import { ModalTypes, useSearchParamModal } from 'hooks/useSearchParamModal';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

const QuizViewProxy = () => {
  const navigate = useNavigate();
  const params = useParams();
  const schoolId = useSelector(s => s.school.schoolId);
  const { navigateToModal } = useSearchParamModal();

  useEffect(() => {
    if (!params?.shortCode) {
      navigate('/');
    } else {
      navigateToModal({
        modalType: ModalTypes.SetWork,
        meta: {
          stage: 'topics',
          quizShortCode: params.shortCode
        },
        baseUrl: `/${schoolId}`
      });
    }
  }, [navigate, navigateToModal, params.shortCode, schoolId]);
};

export default QuizViewProxy;
